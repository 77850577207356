<template>
  <div class="page-body-box">
    <el-radio-group v-model="radio">
      <el-radio-button :label="1">待跟进</el-radio-button>
      <el-radio-button :label="2">待分配</el-radio-button>
    </el-radio-group>
    <ToFollowTask v-if="radio === 1" />
    <ToAllocatedTask v-if="radio === 2" />
  </div>
</template>

<script>
import ToFollowTask from './ToFollowTask.vue'
import ToAllocatedTask from './ToAllocatedTask.vue'
export default {
  name: 'AcademicTaskFollow',
  components: {
    ToFollowTask,
    ToAllocatedTask
  },
  data() {
    return {
      radio: 1
    }
  }
}
</script>

<style lang="scss" scoped>
.page-body-box {
  background: #fff;
}
</style>
